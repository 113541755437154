.wrapper {
  display: flex;
  flex-direction: column;
  margin: 20px 0 10px;

  .title {
    font-family: LatoBold;
    font-size: 18px;
    color: #333;
    margin: 10px 0;
  }

  .header {
    display: flex;
    align-items: center;
    background-color: #ebfbd2;
  }

  .row {
    display: flex;
    align-items: center;

    .cell {
      font-family: LatoRegular;
      font-size: 14px;
      color: #333;
    }
  }

  .total {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &.offset {
      margin-top: 40px;
    }

    .label {
      min-width: 100px;
      font-family: LatoBold;
      font-size: 14px;
    }

    .amount {
      min-width: 100px;
      font-family: LatoRegular;
      font-size: 14px;
    }
  }

  .cell {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: LatoBold;
    font-size: 14px;
    color: #333;
    padding: 5px;

    &.item {
      flex: 1;
    }
    &.qty {
      min-width: 100px;
    }
    &.rate {
      min-width: 100px;
    }
    &.amount {
      min-width: 100px;
    }
  }
}
